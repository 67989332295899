/* KARLA */
@font-face {
  font-family: 'Karla-Regular';
  src: local('Karla-Regular'), url(./karla/Karla-Regular.woff) format('woff'),
    url(./karla/Karla-Regular.eot) format('eot'), url(./karla/Karla-Regular.ttf) format('truetype'),
    url(./karla/Karla-Regular.svg#Karla-Regular) format('svg');
}

@font-face {
  font-family: 'Karla-Bold';
  src: local('Karla-Bold'), url(./karla/Karla-Bold.woff) format('woff'), url(./karla/Karla-Bold.eot) format('eot'),
    url(./karla/Karla-Bold.ttf) format('truetype'), url(./karla/Karla-Bold.svg#Karla-Bold) format('svg');
}

@font-face {
  font-family: 'Karla-Italic';
  src: local('Karla-Italic'), url(./karla/Karla-Italic.woff) format('woff'), url(./karla/Karla-Italic.eot) format('eot'),
    url(./karla/Karla-Italic.ttf) format('truetype'), url(./karla/Karla-Italic.svg#Karla-Italic) format('svg');
}

@font-face {
  font-family: 'Karla-BoldItalic';
  src: local('Karla-BoldItalic'), url(./karla/Karla-BoldItalic.woff) format('woff'),
    url(./karla/Karla-BoldItalic.eot) format('eot'), url(./karla/Karla-BoldItalic.ttf) format('truetype'),
    url(./karla/Karla-BoldItalic.svg#Karla-BoldItalic) format('svg');
}
